export async function post(body) {
  return fetch('https://entryinterschools.com.au/api/v2/waivers', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        // create error object and reject if not a 2xx response code
        let err = new Error('HTTP status code: ' + response.status);
        throw err;
      }
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}
