import React from 'react';
import { Box, ResponsiveContext } from 'grommet';

import { TextField } from 'final-form-material-ui';

import { Field } from 'react-final-form';

import Moment from 'react-moment';
Moment.globalFormat = 'D MMMM YYYY';

export default (props) => {
  const version = props.version || '';

  const type = props.type ? props.type.charAt(0).toUpperCase() + props.type.slice(1) : '';

  const labels = props.isParent
    ? {
        first: 'Parent/Guardian given name',
        last: 'Parent/Guardian surname',
      }
    : {
        first: `${type} given name`,
        last: `${type} surname`,
      };

  const inputNames = props.isParent
    ? {
        first: 'guardianGivenName' + version,
        last: 'guardianSurname' + version,
      }
    : {
        first: 'givenName' + version,
        last: 'surname' + version,
      };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          <Box direction="row">
            <Box
              pad={{
                vertical: 'small',
                horizontal: 'small',
              }}
              width={size === 'small' ? '100%' : '33%'}
            >
              <Field required name={inputNames.first} component={TextField} type="text" label={labels.first} />
            </Box>
            <Box
              pad={{
                vertical: 'small',
                horizontal: 'small',
              }}
              width={size === 'small' ? '100%' : '33%'}
            >
              <Field required name={inputNames.last} component={TextField} type="text" label={labels.last} />
            </Box>
            {props.showDate && (
              <Box
                pad={{
                  horizontal: 'small',
                }}
                width={size === 'small' ? '100%' : '33%'}
              >
                <p>
                  <strong style={{ fontSize: '20px', lineHeight: '2.5', display: 'inline-block' }}>
                    <Moment />
                  </strong>
                </p>
              </Box>
            )}
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};
