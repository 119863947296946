import React from 'react';
import { Heading } from 'grommet';

export default (props: any) => {
  return (
    <>
      <Heading level="2" size="small">
        {props.type} Waiver
      </Heading>
      <p
        style={{
          textAlign: 'center',
        }}
      >
        <strong>DECLARATION, WARRANT, WAIVER AND RELEASE</strong>
      </p>
    </>
  );
};
