import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Field } from 'react-final-form';
import Moment from 'react-moment';
Moment.globalFormat = 'D MMMM YYYY';

export default props => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box pad='small' width={size === 'small' ? '100%' : '66%'}>

          <Field
            name='SchoolId'
            label='Select school'
            style={{ fontSize: '18px' }}
            render={({ input, meta }) => {
              return (
                <React.Fragment>
                  <select {...input}>
                    <option value=''>Please select</option>
                    {props.schools.map((school, index) => (
                      <option key={index} value={school.id}>
                        {school.name}
                      </option>
                    ))}
                  </select>

                  {meta.error && meta.touched && (
                    <p
                      style={{
                        color: 'red',
                        width: '100%',
                        borderTop: '2px solid red',
                        marginTop: '15px',
                        paddingTop: '5px'
                      }}
                    >
                      Required
                    </p>
                  )}
                </React.Fragment>
              );
            }}
          />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};
