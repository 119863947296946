import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import createRouter from './router';

import { RouterProvider } from 'react-router5';
import * as serviceWorker from './serviceWorker';

require('typeface-anton');

const router: any = createRouter();

router.start(() => {
  ReactDOM.render((
      <RouterProvider router={router}>
          <App/>
      </RouterProvider>
  ), document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
