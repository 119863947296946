// import { object, string } from 'yup';
// import { yupParser } from '../Helpers/yupParser';

// const validationSchema = object().shape({
//   given: string().required('Please firstname'),
//   surname: string().required('Please firstname'),
//   school: string().required('Please firstname'),
// });

// export function validator(values: any) {
//   try {
//     validationSchema.validateSync(values, {
//       abortEarly: false,
//     });
//     return {};
//   } catch (err) {
//     return yupParser(err, values);
//   }
// }

export const validator = (values) => {
  const errors = {};

  if (!values.agreeTerms) {
    errors.agreeTerms = 'Required';
  }

  if (!values.givenName) {
    errors.givenName = 'Required';
  }
  if (!values.surname) {
    errors.surname = 'Required';
  }

  if (!values.SchoolId) {
    errors.SchoolId = 'Required';
  }

  if (!values.guardianGivenName) {
    errors.guardianGivenName = 'Required';
  }

  if (!values.guardianSurname) {
    errors.guardianSurname = 'Required';
  }

  if (!values.guardianAge) {
    errors.guardianAge = 'Required';
  }

  if (!values.guardianRelationship) {
    errors.guardianRelationship = 'Required';
  }

  return errors;
};
