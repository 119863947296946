import React from 'react';

import Name from '../../Components/Name';
import SchoolSelect from './SchoolSelect';

export default (props: any) => {
  return (
    <>
      <p>
        <strong>Between the "Host"</strong>
      </p>
      <p>
        Perisher Blue Pty Limited - ACN 061 232 488 As trustee for The Snow Trust ABN 29 420 214 757
        <br />
        Falls Creek Ski Lifts Pty Limited ACN 004 843 761
        <br />
        Mount Hotham Skiing Company Pty Limited ACN 004 294 697
      </p>

      <p>and</p>
      <p>
        <strong>The event "{props.type[0].toUpperCase() + props.type.slice(1)}"</strong>
      </p>
      <Name type={props.type} />

      <SchoolSelect schools={props.schools} />
      <p>
        "<strong>The Event</strong>" means the&nbsp;
        <strong>2024 SUBARU SNOW AUSTRALIA Interschools Event.</strong>
      </p>
      <p>
        "<strong>Organisers</strong>" means <strong> Snow Australia Limited ABN 98 063 859 423.</strong>
      </p>
      <p>
        <strong>RISK WARNING:</strong>
      </p>
      <p>
        <strong>
          ALPINE RECREATIONAL ACTIVITIES INCLUDING SKIING, SNOWBOARDING, USING LIFTS, SNOWTUBING, TOBOGGANING, AND SNOW
          PLAY INVOLVE SIGNIFICANT RISKS WHICH MAY RESULT IN PHYSICAL HARM, INCLUDING PERSONAL INJURY, PERMANENT
          DISABILITY OR EVEN DEATH.
        </strong>
      </p>
      <p>
        <strong>
          SUCH RISKS AND HARM MAY RESULT FROM YOUR ACTIONS AND/OR OMISSIONS OR THOSE OF OTHERS. THESE RISKS INCLUDE, BUT
          ARE NOT LIMITED TO: COLLISIONS WITH ANOTHER PERSON, OBJECTS, SURFACES OR TERRAIN; LOSS OF CONTROL AND/OR
          DIRECTION AND/OR FALLING WHILE PARTICIPATING IN ALPINE ACTIVITIES OR WHILE USING LIFTS; AND UNEXPECTED CHANGES
          IN WEATHER AND SNOW CONDITIONS.
        </strong>
      </p>
    </>
  );
};
