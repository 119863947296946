import React from 'react';
import { Box, ResponsiveContext, Heading } from 'grommet';

import { TextField } from 'final-form-material-ui';
import { Field } from 'react-final-form';

import Name from '../../Components/Name';

export default (props) => {
  const { isMinor } = props;

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          {isMinor && (
            <>
              <Heading level="3" size="small">
                MEDICAL RELEASE FOR MINORS
              </Heading>

              <ol type="A">
                <li>
                  I permit any authorised the Provider employee to arrange for medical attention for the Child or to
                  transfer the Child to the Perisher Valley Medical Centre, Falls Creek Medical Centre, Mount Hotham
                  Medical Centre (Medical Facility), or to a hospital if, in
                  the opinion of that person, medical attention is needed or is likely to be needed for my Child.
                </li>
                <li>
                I agree that on transporting the Child to any hospital or medical facility the
                  Provider will have no further responsibility for, or in respect of, my Child.
                </li>
                <li>
                  I agree to pay all costs associated with such medical care or attention and for related transportation
                  for the Child and I agree to indemnify and to keep indemnified the Provider for
                  and in respect of any such costs incurred.
                </li>
                <li>
                  I further authorise the Medical Facility, its assigns, employees or agents to render any
                  necessary or emergency medical care or attention to the Child if considered necessary by a medical
                  practitioner employed by the Medical Facility.
                </li>
                <li>
                  I am aware that the practice of medicine in a surgery is not an exact science and I acknowledge that
                  no guarantees have been made to me/the Child as to the result of treatment or examinations at the Medical
                  Facility or a hospital.
                </li>
              </ol>

              <Name version="8" isParent={isMinor} showDate={true} />

              <Box direction="row">
                <Box
                  pad={{
                    vertical: 'small',
                    horizontal: 'small',
                  }}
                  width={size === 'small' ? '100%' : '33%'}
                >
                  <Field
                    name={`guardianRelationship`}
                    component={TextField}
                    type="text"
                    label="Relationship of Parent/Guardian to Minor"
                  />
                </Box>
                <Box
                  pad={{
                    vertical: 'small',
                    horizontal: 'small',
                  }}
                  width={size === 'small' ? '100%' : '33%'}
                >
                  <Field name={`guardianAge`} component={TextField} type="text" label="Age of Parent / Guardian" />
                </Box>
              </Box>

              <p>&nbsp;</p>
            </>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};
