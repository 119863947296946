import { Box } from 'grommet';
import React from 'react';
import { RouteNode } from 'react-router5';

import ThredboWaiver from '../Pages/ThredboWaiver';
import Waiver from '../Pages/Waiver';

function NotFound() {
  return <div>Not Found</div>;
}

function Main({ route }) {
  if (!route) return <NotFound />;
  const { type } = route.params;

  if (route.name === 'WaiverParticipantThredbo') return <ThredboWaiver type={type} />;
  return <Waiver type={type} />;
}

export default (props: any) => (
  <Box align="center">
    <Box
      tag="main"
      direction="column"
      width="xlarge"
      pad={{ left: 'large', right: 'large', vertical: 'medium' }}
      background="white"
      elevation="xsmall"
      round="xsmall"
      style={{ zIndex: 1 }}
    >
      <RouteNode nodeName="">{({ route }) => <Main route={route} {...props} />}</RouteNode>
    </Box>
  </Box>
);
