import React from 'react';
import { Box, ResponsiveContext, Heading } from 'grommet';

import { TextField } from 'final-form-material-ui';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Field } from 'react-final-form';

import Name from '../../Components/Name';

export default (props) => {
  const { isMinor, type } = props;

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          <Field
            name="isMinor"
            type="checkbox"
            render={({ input, meta }) => {
              const { value, ...rest } = input;
              return (
                <>
                  <FormControlLabel
                    control={<Checkbox style={{ color: '#156787' }} value="true" {...rest} />}
                    label={`Please tick if the ${type} is under 18 years of age`}
                  />
                </>
              );
            }}
          />
          {isMinor && (
            <>
              <Heading level="3" size="small">
                DECLARATION FOR MINORS
              </Heading>

              <p>
                If you are under 18 years of age on the day this declaration is acknowledged and accepted, the
                declaration must be acknowledged and accepted by your parent or guardian. I warrant and certify that I
                am the parent or guardian of the child named below (“My Child”) who will be under 18 years of age on the
                date of the Event and that he/she has my consent and is capable of participating in the Event. I confirm
                that I have read and understand the above declaration, warrant, waiver and release and that I agree on
                behalf of My Child, to be bound by each of those conditions and having done so, I acknowledge and accept
                voluntarily.
              </p>

              <Name isParent={isMinor} />

              <Name type={type} version="4" showDate={true} />

              <Box direction="row">
                <Box
                  pad={{
                    vertical: 'small',
                    horizontal: 'small',
                  }}
                  width={size === 'small' ? '100%' : '33%'}
                >
                  <Field
                    name={`guardianRelationship`}
                    component={TextField}
                    type="text"
                    label="Relationship of Parent/Guardian to Minor"
                  />
                </Box>
                <Box
                  pad={{
                    vertical: 'small',
                    horizontal: 'small',
                  }}
                  width={size === 'small' ? '100%' : '33%'}
                >
                  <Field name={`guardianAge`} component={TextField} type="text" label="Age of Parent / Guardian" />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};
