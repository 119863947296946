export default {
  global: {
    colors: {
      brand: '#156787',
      focus: '#0AB6F8',
    },
    font: {
      family: 'Arial, Helvetica, sans-serif',
      size: '18px',
      height: '26px'
    }
  },
  heading: {
    extend: {
      color: '#156787',
      fontFamily: 'Anton',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      
    },
    level: {
      '1': {
        small: {
          size: '2.75rem',
          height: '5.5rem'
        }
      },
      '2': {
        
      }
    }
  },
  button: {
    extend: {
    }
  }
};