import React from 'react';

export default ({ type }) => {
  const both = true;

  const participant = type === 'participant';
  const volunteer = type === 'volunteer';

  const typeCapitalised = type[0].toUpperCase() + type.slice(1);
  const typeGerund = type === 'volunteer' ? 'volunteering' : 'participating';

  return (
    <React.Fragment>
      <ol>
        {volunteer && (
          <>
            <li>
              warrant that I am over 18 years of age, medically fit (including physically and mentally) to volunteer in
              the Event and that I have not been advised otherwise by a medical practitioner;
            </li>
          </>
        )}
        {participant && (
          <>
            <li>
              warrant that the {typeCapitalised} is medically fit (including physically and mentally) to participate in
              the Event and that I or My Child have not been advised otherwise by a medical practitioner;
            </li>
            <li>
              warrant that I will inspect the Event area and arenas and all fixtures, fittings, equipment, goods,
              apparatus or other things supplied, provided or otherwise used in or related to the conduct of the Event
              (‘Event Staging Area’) and agree that the {typeCapitalised} shall not{' '}
              {participant ? 'participate in' : 'volunteer for'} the Event unless I am completely satisfied with the
              adequacy and condition of the Event Staging Area;
            </li>
          </>
        )}

        {volunteer && (
          <>
            <li>
              warrant that I will inspect the Event area and arenas and all fixtures, fittings, equipment, goods,
              apparatus or other things supplied, provided or used in or related to the conduct of the Event (‘Event
              Staging Area’) and agree that I will not {participant ? 'participate in' : 'volunteer for'} the Event
              unless I am completely satisfied with the adequacy and condition of the Event Staging Area;
            </li>
          </>
        )}

        {both && (
          <>
            <li>
              acknowledge the risk warning set out in bold and capitals above and that the warning constitutes a risk
              warning pursuant to the Civil Liability Act 2002 (NSW), the Wrongs Act 1958 (Vic), the Competition &amp;
              Consumer Act 2010 (CCA) (Cth), including the Australian Consumer Law, and the common law, and I agree that
              I assume all risks in connection with {participant && `the ${typeCapitalised}'s participation in`} the
              activities and acknowledge that the Event Host is not liable for the breach of any express or implied
              warranty that services it provides will be provided with reasonable care and skill;
            </li>
          </>
        )}

        {participant && (
          <>
            <li>
              agree that the {typeCapitalised} shall abide by the rules and conditions of the Event, including those in
              any literature or verbal or written instructions and that I and/or the {typeCapitalised} will at all times
              comply with the Alpine Responsibility Code (See link below);
            </li>
          </>
        )}

        {volunteer && (
          <>
            <li>
              agree that I will abide by the rules and conditions of the Event including those in any literature or
              verbal or written instructions and that I will at all times comply with the Alpine Responsibility Code
              (See link below);
            </li>
          </>
        )}

        {both && (
          <>
            <li>
              agree to wear a helmet approved for snowsports activity while{' '}
              {participant ? 'participating in' : 'volunteering for'} the Event;
            </li>

            <li>
              acknowledge that I engage in recreational activity at my own risk and except to the extent that the law,
              including the Competition and Consumer Act 2010 (CCA) (Cth) and the Australian Consumer Law, provides that
              liability cannot be excluded, {participant ? 'and ' : 'I '} agree to waive, release and discharge all and
              any claim, right or cause of action however arising including from any negligent act or omission of any
              person, whether or not presently ascertained, immediate, future or contingent, which I{' '}
              {participant && `and/or the ${typeCapitalised} `}
              may otherwise have for or arising out of loss of {volunteer && `my `} life or injury, damage or loss of
              any description whatsoever and howsoever caused which I {participant && `and/or the ${typeCapitalised} `}{' '}
              may suffer or sustain in the course of or consequent upon {volunteer && `my `}{' '}
              {participant ? 'participation' : 'volunteering'} in the Event;
            </li>

            <li>
              acknowledge that to the maximum extent permitted by the CCA, the Event Host excludes liability to{' '}
              {participant ? 'the Participant' : 'me'} for:
              <ol type="i">
                <li>death;</li>
                <li>personal injury;</li>
                <li>the contraction, aggravation or acceleration of a disease; and</li>
                <li>
                  the coming into existence, the aggravation, acceleration or recurrence of any other condition,
                  circumstance, occurrence, activity, form of behaviour, course of conduct or state of affairs in
                  relation to an individual:
                  <ol type="i">
                    <li>that is or may be harmful or disadvantageous to an individual or community; or</li>
                    <li>that may result in harm or disadvantage to an individual or community.</li>
                  </ol>
                </li>
              </ol>
              arising out of any failure by the Event Host, its employees or agents to comply with any consumer
              guarantees applying to the services provided to {participant ? 'you' : 'me'} by the Event Host, as the
              term “services” is defined in the Australian Consumer Law, save that, the exclusion of liability outlined
              in paragraph 7 above shall not apply where the conduct of the Event Host, its employees or agents has been
              “reckless” in providing the services to {participant ? 'the Participant' : 'Me'}, as that term is defined
              in section 139A of the CCA.
            </li>

            <li>
              agree that, to the maximum extent permitted by law, to indemnify the Event Host against any claim, right
              or cause of action howsoever arising including from any negligent act or omission of any person, whether
              or not presently ascertained, immediate, future or contingent which I may have for or arising out of loss
              of my life or injury, damage or loss of any description whatsoever and howsoever caused which{' '}
              {participant ? 'the Participant' : 'I'} may suffer or sustain in the course of or consequent upon{' '}
              {participant ? 'participation' : 'my volunteering'} in the Event. Where the Event Host’s liability cannot
              be excluded, I agree to limit the Event Host’s liability to the maximum extent that permitted by law;
            </li>

            <li>
              agree that the waiver, release and discharge contained in paragraph 6 above and the indemnity contained in
              paragraph 8 above shall operate in favour of the Event Host and its employees, assigns, Sponsors, agents,
              officers, directors and related bodies corporate (as defined in the Corporations Act 2001 (Cth)), each the
              “Released Persons”, and shall so operate whether or not the loss, injury or damage is due or attributable
              to an act, neglect or omission (including from any negligent act or omission of any person) of any one or
              more of the Released Persons;
            </li>

            <li>
              agree that the waiver, release and discharge contained in paragraph 6 above and the indemnity contained in
              paragraph 8 above may be pleaded as a complete bar to any claim, notice, demand, action, proceeding,
              litigation or judgment which has or may be brought or made or recovered against any or each of the
              Released Persons;
            </li>

            <li>
              [if relevant] agree to sign off from my rostered day of work to{' '}
              {participant ? 'participate' : 'volunteer'} in the Event (Please note that the Event Host employees MUST
              be signed off work for the day of the Event).
            </li>
          </>
        )}

        {volunteer && (
          <>
            <li>
              acknowledge and agree that where any of the consumer guarantees under the CCA apply to the services
              supplied by the Event Hosts and the Sponsors to Me, and the CCA voids or prohibits a provision in a
              contract excluding, restricting or modifying such consumer guarantees, then, to the fullest extent
              permitted by law, the Event Hosts’ and the Sponsors’ liability under or arising out of the supply of
              services for breach of, or failure to comply with, such consumer guarantees shall be, limited at the
              option of the Event Hosts and/or Sponsors to the re-supply of the relevant services by the Event Hosts
              and/or Sponsors or the payment by the Event Hosts and/or Sponsors of the cost of having the services
              supplied again; and
            </li>
            <li>
              agree that, without limitation to the waiver, release and discharge contained in paragraph 6 above and the
              indemnity contained in paragraph 8 above, the liability of an Event Host will be limited to that part of
              the Event which is located at or held on its premises and an Event Host will not be liable in any
              circumstance or to any extent for any action arising in relation to that part of the Event which is
              located at or held on the premises of another Event Host.
            </li>
          </>
        )}

        {participant && (
          <>
            <li>
              [if relevant] acknowledge and agree that where any of the consumer guarantees under the CCA apply to the
              services supplied by the Event Host, and the CCA voids or prohibits a provision in a contract excluding,
              restricting or modifying such consumer guarantees, then, to the fullest extent permitted by law, the Event
              Host’s liability under or arising out of the supply of services for breach of, or failure to comply with,
              such consumer guarantees shall be, limited at the option of the Event Host to the re-supply of the
              relevant services by the Event Host or the payment by the Event Host of the cost of having the services
              supplied again; and
            </li>
            <li>
              agree and acknowledge that that performing aerial manoeuvres and specifically, inverted aerial manoeuvres
              where the head travels below the level of the hips (“Inverts” such as flips or somersaults) is inherently
              dangerous and carries a high risk of serious personal injury, illness, permanent disability and/or death.
            </li>
            <li>
              agree and acknowledge that I shall not attempt Inverts and confirm that I do not consent to My Child (who
              is a minor) attempting Inverts.
            </li>
          </>
        )}
      </ol>
    </React.Fragment>
  );
};
