import React from 'react';
import { Heading } from 'grommet';

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Field } from 'react-final-form';

export default (props) => {
  return (
    <React.Fragment>
      <Heading level="3" size="small">
        Privacy
      </Heading>
      <p>
        The Event Host collects the personal information requested on this form to enable the efficient provision of the
        goods and/or services that you have requested and to complete the administrative and payment functions
        associated with that transaction. The Global Privacy Policy sets out our policies on the management of personal
        information. To get a copy, please write to Perisher Blue Pty Limited, Attn: Privacy, PO Box 42, Perisher
        Valley, NSW 2624 or you can also telephone us on 1300 655 822, visit our website at{' '}
        <a href="www.perisher.com.au">www.perisher.com.au</a> where the terms and conditions of the Privacy Policy can
        be found in full or email to <a href="mailto:privacy@perisher.com.au">privacy@perisher.com.au</a>
      </p>
    </React.Fragment>
  );
};
