export function get() {
  return fetch(
    'https://entryinterschools.com.au/api/v2/schools?filter=' +
      JSON.stringify({
        fields: {
          id: true,
          name: true,
        },
        order: 'name',
        where: { archived: null },
      })
  ).then((response) => response.json());
}
