import React from 'react';
import { ResponsiveContext } from 'grommet';

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Field } from 'react-final-form';

export default (props) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Field
          name="inverts"
          type="checkbox"
          render={({ input, meta }) => {
            const { value, ...rest } = input;

            return (
              <>
                <FormControlLabel
                  control={<Checkbox style={{ color: '#156787' }} value={value ? 'true' : 'false'} {...rest} />}
                  label="By ticking this box, I hereby warrant and declare that I shall not to attempt Inverts, and that I do not consent to and will not allow My Child (defined below) to attempt Inverts."
                />

                {meta.error && meta.touched && (
                  <p
                    style={{
                      color: 'red',
                      width: '100%',
                      borderTop: '2px solid red',
                      marginTop: '-5px',
                      paddingTop: '5px',
                    }}
                  >
                    Required
                  </p>
                )}
              </>
            );
          }}
        />
      )}
    </ResponsiveContext.Consumer>
  );
};
