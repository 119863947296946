import React, { Component } from 'react';
import { Grommet } from 'grommet';

import Header from './Components/Header';

import Main from './Components/Main';
import theme from './theme';

class App extends Component {
  render() {
    return (
      <Grommet theme={theme}>
        <Header />
        <Main />
        <p>&nbsp;</p>
      </Grommet>
    );
  }
}

export default App;
