import React, { Component } from 'react';
import { Button as GrommetButton } from 'grommet';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Form } from 'react-final-form';

import { get } from '../Services/School';
import { post } from '../Services/Waiver';
import Name from '../Components/Name';
import Header from './Waiver/Header';
import Intro from './Waiver/Intro';
import Inverts from './Waiver/Inverts';
import Bullets from './Waiver/Bullets';
import Minors from './Waiver/Minors';
import Publicity from './Waiver/Publicity';
import Privacy from './Waiver/Privacy';

import { validator } from './Waiver.validator';
import MinorsMedical from './Waiver/MinorsMedical';

export default class Waiver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schools: [],
      isMinor: true,
      open: false,
      message: '',
    };

    this.type = props.type;

    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    get().then((schools) => {
      this.setState({
        schools: schools.map((school) => ({ id: school.id, name: school.name })),
      });
    });
  }

  validate(values) {
    return validator(values, this.type);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  async onSubmit(values, form) {
    const {
      givenName,
      surname,
      isMinor,
      SchoolId,
      guardianGivenName,
      guardianSurname,
      guardianRelationship,
      guardianAge,
    } = values;

    const body = {
      givenName,
      surname,
      name: givenName + ' ' + surname,
      isMinor,
      type: this.type,
      SchoolId,
      createdBy: 'React Waiver Form',
    };

    if (isMinor) {
      body.guardianName = guardianGivenName + ' ' + guardianSurname;
      body.guardianRelationship = guardianRelationship;
      body.guardianAge = guardianAge;
    }

    return post(body)
      .then((values) => {
        form.restart();
        this.setState({ open: true, message: 'Your waiver has been successfully submitted' });
      })
      .catch((err) => {
        this.setState({ open: true, message: 'There was an error' });
      });
  }

  render() {
    return (
      <>
        <Form
          validate={this.validate}
          onSubmit={this.onSubmit}
          initialValues={{ isMinor: true }}
          render={({ handleSubmit, submitting, invalid, reset, values, errors }) => (
            <form onSubmit={handleSubmit} noValidate>
              <section>
                <Header type={this.type} />

                <Intro type={this.type} schools={this.state.schools} />

                <p>
                  {this.type === 'participant' ? (
                    <strong>
                      In consideration for, and as a condition of entry, the {this.type}'s entry in this Event, I:
                    </strong>
                  ) : (
                    <strong>
                      IN CONSIDERATION FOR, AND AS A CONDITION OF MY VOLUNTEERING FOR THE EVENT, INCLUDING WITHOUT
                      LIMITATION, MARSHALLING, GATEKEEPING AND PERFORMING VARIOUS DUTIES DURING THE EVENT AND
                      PARTICIPATING IN ANY OR ALL PRE OR POST-EVENT ACTIVITIES AND SOCIAL EVENTS, AND/OR MY BEING
                      PERMITTED TO OFFICIATE, OBSERVE, WORK FOR OR IN ANY MANNER PARTICIPATE IN THE EVENT OR ITS
                      STAGING, I:
                    </strong>
                  )}
                </p>

                <Bullets type={this.type} />

                {this.type === 'participant' && <Inverts />}

                <p>
                  <strong>
                    I HAVE READ CAREFULLY AND UNDERSTAND THIS DECLARATION, WARRANT, WAIVER AND RELEASE, AND HAVING DONE
                    SO, I ACKNOWLEDGE AND ACCEPT VOLUNTARILY.
                  </strong>
                </p>
                <Name type={this.type} version="2" showDate={true} />
                <p>&nbsp;</p>
              </section>
              {this.type === 'participant' && (
                <section>
                  <Minors type={this.type} isMinor={values.isMinor} />
                </section>
              )}
              <section>
                <Privacy type={this.type} isMinor={values.isMinor} />
              </section>
              <section>
                <Publicity type={this.type} isMinor={values.isMinor} />
              </section>
              {this.type === 'participant' && (
                <section>
                  <MinorsMedical type={this.type} isMinor={values.isMinor} />
                </section>
              )}
              <GrommetButton
                style={{ fontSize: '1.75rem', lineHeight: '1.5', fontFamily: 'Anton', textTransform: 'uppercase' }}
                type="submit"
                primary
                label="Submit"
                disabled={submitting}
              />
            </form>
          )}
        />

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{this.state.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
