import React from 'react';
import { Heading } from 'grommet';

import Name from '../../Components/Name';

export default (props) => {
  const { isMinor, type } = props;

  const participant = type === 'participant';

  return (
    <React.Fragment>
      <Heading level="3" size="small">
        PUBLICITY RELEASE
      </Heading>
      <p>
        I unconditionally assign to the Event Host all right title and interest I{participant && '/My Child'} may have
        in any and all audio, audio visual and/or photographic recording of me
        {participant && '/My Child'} and grant to the Event Host permission to use, display, licence, sell and publish
        or otherwise deal with the audio, audio visual and/or photographic recording of me{participant && '/My Child'},
        including for the purpose of advertisement, promotion or otherwise. I further agree that any such recording(s)
        shall remain the exclusive property of the Event Host.
      </p>
      <Name type={type} version="6" showDate={true} isParent={isMinor} />
    </React.Fragment>
  );
};
