import React, { Component } from 'react';
import { Button as GrommetButton, Box, Heading, ResponsiveContext } from 'grommet';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Form } from 'react-final-form';

import { get } from '../Services/School';
import { post } from '../Services/Waiver';
import Name from '../Components/Name';

import { TextField } from 'final-form-material-ui';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Field } from 'react-final-form';

import SchoolSelect from './Waiver/SchoolSelect';

import { validator } from './ThredboWaiver.validator';

export default class ThredboWaiver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schools: [],
      open: false,
    };

    this.type = props.type;

    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    get().then((schools) => {
      this.setState({
        schools: schools.map((school) => ({ id: school.id, name: school.name })),
      });
    });
  }

  validate(values) {
    return validator(values, this.type);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  async onSubmit(values, form) {
    const { givenName, surname, SchoolId, guardianGivenName, guardianSurname, guardianRelationship, guardianAge } =
      values;

    const body = {
      givenName,
      surname,
      name: givenName + ' ' + surname,
      type: this.type,
      SchoolId,
      createdBy: 'React Waiver Form',
      resort: 'thredbo',
    };

    body.guardianName = guardianGivenName + ' ' + guardianSurname;
    body.guardianRelationship = guardianRelationship;
    body.guardianAge = guardianAge;

    return post(body)
      .then((values) => {
        form.restart();
        this.setState({ open: true });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <Form
              validate={this.validate}
              onSubmit={this.onSubmit}
              initialValues={{ isMinor: true }}
              render={({ handleSubmit, submitting, invalid, reset, values, errors }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <section>
                    <Heading level="2" size="small">
                      THREDBO EVENT PARTICIPANT TERMS AND CONDITIONS - 2024
                    </Heading>

                    <p>
                      <strong>RISK WARNING:</strong>
                    </p>
                    <p>
                      <strong>
                        RECREATIONAL ACTIVITIES (INCLUDING SKIING, SNOWBOARDING, USING LIFTS, MOUNTAIN BIKING AND TO A
                        LESSER DEGREE HIKING) INVOLVE A SIGNIFICANT RISK OF PHYSICAL HARM OR PERSONAL INJURY INCLUDING
                        PERMANENT DISABILITY AND/OR DEATH TO PARTICIPANTS. ANY SUCH INJURY MAY RESULT NOT ONLY FROM YOUR
                        ACTIONS BUT FROM THE ACTION, OMISSION OR NEGLIGENCE OF OTHERS.
                      </strong>
                    </p>
                    <p>
                      Issued by Kosciuszko Thredbo Pty Ltd (referred to throughout as 'us', 'we', 'our') as operator of
                      the Thredbo Alpine Resort ('Resort' or 'Thredbo').
                    </p>

                    <Name type={this.type} />

                    <SchoolSelect schools={this.state.schools} />

                    <Heading
                      level="3"
                      size="medium"
                      style={{
                        marginTop: '35px',
                      }}
                    >
                      TERMS AND CONDITIONS OF USE
                    </Heading>

                    <p>
                      By participating in a Thredbo Event or accessing the Resort you acknowledge that you have read and
                      understood, and agree to be bound by, these Terms and Conditions of Use. Any adults who are
                      parents or guardians of children who are unable to accept these Terms and Conditions of Use in
                      their own capacity, do so on their behalf and must ensure they comply with them. Separate and
                      additional terms and conditions may apply to the use of some of our facilities, equipment and
                      services.
                    </p>

                    <p>
                      These Terms and Conditions of Use refer to various rights that may be exercised in our discretion,
                      and the discretion of our staff. Mountain based recreational activities can be dangerous, and as a
                      result we need the right to do so. We will, however, endeavour to ensure those rights are
                      exercised reasonably in accordance with all applicable laws, and having regard to the need to
                      protect staff, customers, property and equipment.
                    </p>
                  </section>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    General Provisions:
                  </Heading>
                  <ul>
                    <li>
                      You must observe the Alpine Responsibility Code (as set out below and as may be updated by notices
                      displayed throughout the Resort) and conduct yourself in a safe, responsible, and controlled
                      manner at all times.
                    </li>
                    <li>
                      The use of any mountain sport equipment or devices, whether recreational or otherwise (other than
                      mountain bikes when permitted or other equipment we approve), is not permitted within the Resort
                      boundary.
                    </li>
                    <li>
                      You must read and comply with all signs and follow all reasonable directions given by us, our
                      staff, and by any public health official or law enforcement officer.
                    </li>
                    <li>
                      You acknowledge that the risks associated with mountain-based recreational activities are not
                      always prominent, conspicuous, or physically observable, and you accept those risks nonetheless.
                    </li>
                  </ul>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Limitations and Exclusions of Liability:
                  </Heading>
                  <p>
                    This section contains limitations and exclusions on our liability. Those limitations and exclusions
                    are not intended to apply in any circumstances where the law does not permit us to do so, whether
                    under the Competition and Consumer Act 2010 (Cth), the Civil Liability Act 2002 (NSW), or otherwise.
                  </p>
                  <ul>
                    <li>You engage in any recreational activity at your own risk.</li>
                    <li>
                      Where we provide "recreational services" as defined in the Competition and Consumer Act 2010 (Cth)
                      (which include sporting activities or similar leisure time pursuits), to the maximum extent
                      permitted by that legislation, we exclude all liability to you for personal injury (as defined
                      below) arising out of any failure by us (or our employees or agents) to comply with any consumer
                      guarantees applying under that legislation (including those set out in the Australian Consumer
                      Law). For these purposes “personal injury” means:
                      <ul>
                        <li>death;</li>
                        <li>
                          any physical or mental injury of an individual (including the aggravation, acceleration, or
                          recurrence of such an injury);
                        </li>
                        <li>the contraction, aggravation, or acceleration of a disease of an individual; and</li>
                        <li>
                          the coming into existence, the aggravation, acceleration, or recurrence of any other
                          condition, circumstance, occurrence, activity, form of behaviour, course of conduct, or state
                          of affairs in relation to an individual:
                          <ul>
                            <li>that is or may be harmful or disadvantageous to an individual or community; or</li>
                            <li>that may result in harm or disadvantage to an individual or community.</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Where liability cannot be excluded, we limit our liability to the maximum extent permitted by law.
                    </li>
                    <li>
                      Without limiting paragraph 2 above, all warranties, representations, or conditions relating to the
                      services we provide (whether express or implied and whether arising out of contract, at common
                      law, or under statute) not set out in these Terms and Conditions of Use are excluded.
                    </li>
                    <li>
                      We are not liable to you (on any basis) for any indirect or consequential loss or damage to you.
                    </li>
                    <li>
                      However, nothing limits our liability for representations or other communications (either oral or
                      written) made by us where by law such liability cannot be excluded.
                    </li>
                    <li>The term “including” in these Terms and Conditions of Use is not a term of limitation.</li>
                  </ul>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Consideration for Participation:
                  </Heading>
                  <p>In consideration for and as a condition of my entry as a participant in this event I hereby:</p>
                  <ul>
                    <li>
                      warrant that I am medically fit (including physically and mentally) to participate in the Event
                      and that I have not been advised otherwise by a medical practitioner.
                    </li>
                    <li>
                      warrant that I will inspect the Event area and arenas and all fixtures, fittings, equipment,
                      goods, apparatus, or other things supplied, provided, or used in or related to the conduct of the
                      Event (‘Event Staging Area’) and agree that I will not participate in the Event unless I am
                      completely satisfied with the adequacy and condition of the Event Staging Area;
                    </li>
                    <li>
                      agree that I will abide by the rules and conditions of the Event including those in any
                      literature, KT website, or verbal or written instructions and that I will at all times comply with
                      the Alpine Responsibility Code;
                    </li>
                    <li>
                      [if relevant] agree to sign off from my rostered day of work to participate in the Event. (Please
                      note that KT employees MUST be signed off work for the day of the Event);
                    </li>
                    <li>
                      agree and acknowledge by my participation in the Event that performing aerial manoeuvres and
                      specifically inverted aerial manoeuvres or off-axis rotations (“Inverts”) is inherently dangerous
                      and carries a high risk of serious personal injury, illness, permanent disability, and/or death
                      which may or will result in severe economic and/or property loss and damage and I fully recognise
                      and accept those risks;
                    </li>
                    <li>
                      agree that my Event entry is non-refundable if any portion of the Event entry is unused. The Event
                      entry is non-transferrable to another person and/or event; and
                    </li>
                    <li>
                      acknowledge that I have already purchased a KT lift or mountain access pass and agree that the
                      Limitations and Exclusions of Liability terms in that pass also apply to my participation in the
                      Event.
                    </li>
                    <li>
                      agree that If you have been involved in an incident where there is the potential for concussion,
                      you are required to provide a doctor’s certificate or suitable medical clearance to participate in
                      the event.
                    </li>
                  </ul>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Kosciuszko Thredbo Pty Ltd Helmet Policy
                  </Heading>
                  <p>
                    KT promotes the use of appropriate protective equipment to minimise the risk of injury - including
                    helmets. KT actively encourages participants to educate themselves on the benefits and limitations
                    of helmets. The fundamental principle to achieve slope safety is to abide by the Alpine
                    Responsibility Code by skiing and snowboarding in control. KT believes this is the first line of
                    defense; protective equipment further mitigates the risk of injury.
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Rules and Regulations
                  </Heading>
                  <p>
                    I hereby unconditionally assign to KT all right, title, and interest I/My Child may have in any and
                    all audio, audio-visual, and/or photographic recording of me/My Child taken by KT or any of its
                    employees or agents at an Event (“Recordings”) and grant to KT permission to use, display, license,
                    sell, and publish or otherwise deal with such Recordings including for the purpose of advertisement,
                    promotion, or otherwise. I further agree that any such Recordings shall remain the exclusive
                    property of KT.
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Privacy Statement
                  </Heading>
                  <p>
                    We collect, store, and use the personal information which you provide to us in accordance with our
                    Privacy Policy available at{' '}
                    <a href="https://www.thredbo.com.au/privacy-policy/">https://www.thredbo.com.au/privacy-policy/</a>
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Publishing Event Results by Snow Australia
                  </Heading>
                  <p>
                    Snow Australia publishes Thredbo event details and results at:{' '}
                    <a href="https://www.snow.org.au/">https://www.snow.org.au/</a>. We need to share your personal
                    information (first name, last name, gender, and year of birth) with Snow Australia who will collect,
                    store, and use it in accordance with their privacy policy available at:{' '}
                    <a href="https://www.snow.org.au/about-snowaus/important-documents">
                      https://www.snow.org.au/about-snowaus/important-documents
                    </a>
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Publicity Release
                  </Heading>
                  <p>
                    You consent and authorize us to use any and all audio, audio-visual, and/or photographic recording
                    of you/your Child relating to Thredbo Events (unless otherwise specified). If you do not consent to
                    the use of audio, audio-visual, and/or photographic recording of you/your Child, please contact{' '}
                    <a href="mailto:guestservices_thredbo@evt.com">guestservices_thredbo@evt.com</a>.
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Declaration for Minors
                  </Heading>
                  <p>
                    If you are under 18 years of age on the day this declaration is provided, the declaration must be
                    given by your parent or guardian. I warrant and certify that I am the parent or guardian of the
                    child entering this Event and that he/she has my consent and is capable of participating in the
                    Event. I confirm that I have read and understand the above terms and conditions and that I agree on
                    behalf of My Child to be bound by each of those terms and conditions and having done so, I agree
                    voluntarily.
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Medical Release for Minors
                  </Heading>
                  <p>
                    I permit any authorised KT employee to arrange for medical attention for My Child or to transfer my
                    child to the Thredbo Medical Centre or to a hospital if in the opinion of that person medical
                    attention is needed or is likely to be needed for My Child.
                  </p>
                  <p>
                    I agree that on transporting my child to any hospital or medical facility KT shall have no further
                    responsibility for or in respect of My Child.
                  </p>
                  <p>
                    I agree to pay all costs associated with such medical care or attention and for related
                    transportation for my child and I agree to indemnify and to keep indemnified KT for and in respect
                    of any such costs incurred.
                  </p>
                  <p>
                    I further authorise the Thredbo Medical Centre, its assigns, employees, or agents to render any
                    necessary or emergency medical care or attention to My Child if considered necessary by a medical
                    practitioner employed by the Valley Terminal Medical Centre.
                  </p>
                  <p>
                    I am aware that the practice of medicine in a surgery is not an exact science and I acknowledge that
                    no guarantees have been made to me as to the result of treatment or examinations at the Valley
                    Terminal Medical Centre or at any hospital.
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Thredbo Cancellation Policy
                  </Heading>
                  <p>
                    Please refer to{' '}
                    <a href="https://www.thredbo.com.au/terms-conditions/">
                      https://www.thredbo.com.au/terms-conditions/
                    </a>{' '}
                    for terms and conditions in relation to canceling a Thredbo product or service.
                  </p>

                  <Heading
                    level="3"
                    size="small"
                    style={{
                      marginTop: '35px',
                      marginBottom: '-10px',
                    }}
                  >
                    Force Majeure
                  </Heading>
                  <p>
                    To the extent permitted by law and subject to Thredbo’s Cancellation Policy, we will not be liable
                    to you for any loss or damage (whether direct or consequential) nor be in default under these Terms
                    and Conditions for failure to observe or perform any of our obligations under these Terms and
                    Conditions for any reason or cause which could not with reasonable diligence be controlled or
                    prevented. These reasons or causes include any act of God, strike, lockout or other industrial
                    disturbance or labour difficulty, act of public enemy, war (whether declared or not), blockade,
                    revolution, riot, insurrection, civil commotion, pandemic, epidemic, outbreaks of infectious disease
                    or any other public health crisis or any governmental orders or measures imposed to address such
                    public health crisis including quarantine or any other social restrictions, lightning, storm, flood,
                    fire, earthquake or any other natural disaster or extreme weather condition, explosion, any action,
                    inaction, demand, order, restraint, restriction, change in law, requirement, prevention, frustration
                    or hindrance by or of any person, government or competent authority, embargoes, unavailability of
                    any essential equipment, chemicals or other materials, unavoidable accident, lack of transportation
                    and any other cause, whether specifically referred to above or otherwise, which is not within our
                    reasonable control.
                  </p>

                  <Field
                    name="agreeTerms"
                    type="checkbox"
                    render={({ input, meta }) => {
                      const { value, ...rest } = input;
                      return (
                        <>
                          <FormControlLabel
                            control={<Checkbox style={{ color: '#156787' }} {...rest} />}
                            label={`I have read carefully and understand these terms and conditions.`}
                          />
                          {meta.error && meta.touched && (
                            <p
                              style={{
                                color: 'red',
                                width: '100%',
                                borderTop: '2px solid red',
                                marginTop: '-5px',
                                paddingTop: '5px',
                              }}
                            >
                              Required
                            </p>
                          )}
                        </>
                      );
                    }}
                  />

                  <Name isParent={true} showDate={true} />

                  <Box direction="row">
                    <Box
                      pad={{
                        vertical: 'small',
                        horizontal: 'small',
                      }}
                      width={size === 'small' ? '100%' : '33%'}
                    >
                      <Field
                        name={`guardianRelationship`}
                        component={TextField}
                        type="text"
                        label="Relationship of Parent/Guardian to Minor"
                      />
                    </Box>
                    <Box
                      pad={{
                        vertical: 'small',
                        horizontal: 'small',
                      }}
                      width={size === 'small' ? '100%' : '33%'}
                    >
                      <Field name={`guardianAge`} component={TextField} type="text" label="Age of Parent / Guardian" />
                    </Box>
                  </Box>

                  <GrommetButton
                    style={{
                      fontSize: '1.75rem',
                      lineHeight: '1.5',
                      fontFamily: 'Anton',
                      textTransform: 'uppercase',
                      marginTop: '25px',
                    }}
                    type="submit"
                    primary
                    label="Submit"
                    disabled={submitting}
                  />
                </form>
              )}
            />
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your waiver has been successfully submitted
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}
