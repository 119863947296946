import React from 'react';
import { Box, Heading, Text } from 'grommet';

import background from '../Images/bg-top.jpg';
import snow from '../Images/snow.png';

const Header = (props: any) => (
  <Box
    align="center"
    background={`url(${background})`}
    margin="0 0 2rem 0"
    style={{
      borderBottom: '9px solid #378fb5',
      backgroundSize: 'auto',
      zIndex: 5,
    }}
  >
    <Box
      tag="header"
      background={{
        position: 'right top',
        image: `url(${snow})`,
      }}
      height="114px"
      width="xlarge"
      pad={{ left: 'small', right: 'small', vertical: 'small' }}
      style={{
        backgroundSize: 'auto',
      }}
    >
      <Heading
        size="small"
        margin="0"
        style={{
          color: 'white',
          textTransform: 'uppercase',
          textShadow: '3px 3px 0 #126787',
        }}
      >
        Interschools
        <Text
          color="brand"
          size="2.75rem"
          style={{
            paddingLeft: '0.75rem',
            textShadow: '3px 3px 0 #0ab6f8',
          }}
        >
          Entry System
        </Text>
      </Heading>
    </Box>
  </Box>
);

export default Header;
