// import { object, string } from 'yup';
// import { yupParser } from '../Helpers/yupParser';

// const validationSchema = object().shape({
//   given: string().required('Please firstname'),
//   surname: string().required('Please firstname'),
//   school: string().required('Please firstname'),
// });

// export function validator(values: any) {
//   try {
//     validationSchema.validateSync(values, {
//       abortEarly: false,
//     });
//     return {};
//   } catch (err) {
//     return yupParser(err, values);
//   }
// }

export const validator = (values, type) => {
  const errors = {};
  if (!values.givenName) {
    errors.givenName = 'Required';
  }
  if (!values.surname) {
    errors.surname = 'Required';
  }

  if (!values.givenName2) {
    errors.givenName2 = 'Required';
  }
  if (!values.surname2) {
    errors.surname2 = 'Required';
  }

  if (!values.SchoolId) {
    errors.SchoolId = 'Required';
  }
  if (type === 'participant' && !values.inverts) {
    errors.inverts = 'Required';
  }

  if (!values.isMinor) {
    if (!values.givenName6) {
      errors.givenName6 = 'Required';
    }
    if (!values.surname6) {
      errors.surname6 = 'Required';
    }
  }

  if (values.isMinor) {
    if (!values.guardianGivenName6) {
      errors.guardianGivenName6 = 'Required';
    }
    if (!values.guardianSurname6) {
      errors.guardianSurname6 = 'Required';
    }
  }

  if (values.isMinor && type !== 'volunteer') {
    if (!values.givenName4) {
      errors.givenName4 = 'Required';
    }
    if (!values.surname4) {
      errors.surname4 = 'Required';
    }
    if (!values.guardianGivenName) {
      errors.guardianGivenName = 'Required';
    }
    if (!values.guardianGivenName8) {
      errors.guardianGivenName8 = 'Required';
    }
    if (!values.guardianSurname) {
      errors.guardianSurname = 'Required';
    }
    if (!values.guardianSurname8) {
      errors.guardianSurname8 = 'Required';
    }
    if (!values.guardianAge) {
      errors.guardianAge = 'Required';
    }
    if (!values.guardianRelationship) {
      errors.guardianRelationship = 'Required';
    }
  }

  return errors;
};
